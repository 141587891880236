import { StacObject } from './stac-object.model';
import { StacLink } from './stac-link.model';

export class StacItemCollection {
  type: string = 'FeatureCollection';
  items: StacObject[] = [];
  links: StacLink[] = [];
  numberMatched?: number;
  numberReturned?: number;
  extras: Map<string, any> = new Map();

  constructor(obj: any) {
    if (obj.type) this.type = obj.type;
    if (obj.features) {
      for (const feature of obj.features) {
        this.items.push(new StacObject(feature));
      }
    }
    if (obj.links) this.links = obj.links;
    if (obj.numberMatched) this.numberMatched = obj.numberMatched;
    if (obj.numberReturned) this.numberReturned = obj.numberReturned;

    for (const key in obj) {
      if (!this.extras.has(key) && !(key in this)) {
        this.extras.set(key, obj[key]);
      }
    }
  }
}
