export class StacAsset {
  private _id: string = '';
  href: string = '';
  title?: string;
  type?: string;
  description?: string;
  roles?: string[];
  extras: Map<string, any> = new Map();

  constructor(id: string, obj: any) {
    this._id = id;
    if (obj.href) this.href = obj.href;
    if (obj.title) this.title = obj.title;
    if (obj.type) this.type = obj.type;
    if (obj.description) this.description = obj.description;
    if (obj.roles) this.roles = obj.roles;

    for (const key in obj) {
      if (!this.extras.has(key) && !(key in this)) {
        this.extras.set(key, obj[key]);
      }
    }
  }

  get id(): string {
    return this._id;
  }

  find(term: string) {
    const lowerTerm = term.toLowerCase();

    return (
      this._id.toLowerCase().includes(lowerTerm) ||
      this.title?.toLowerCase().includes(lowerTerm) ||
      this.description?.toLowerCase().includes(lowerTerm) ||
      this.roles?.filter((k) => k.toLowerCase().includes(lowerTerm)).length
    );
  }
}
