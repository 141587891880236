import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map, of, toArray, mergeMap, from, tap, concat, catchError } from 'rxjs';
import { Location } from '@angular/common';
import { StacObject } from '../models/stac-object.model';
import { StacCollections } from '../models/stac-collections.model';
import { StacItemCollection } from '../models/stac-item-collection.model';

@Injectable({
  providedIn: 'root',
})
export class StacAPIService {
  private http = inject(HttpClient);

  readStacObject(url: string): Observable<StacObject> {
    return url
      ? this.http.get(url).pipe(
          map((obj: any) => new StacObject(obj, url)),
          catchError(() => of(new StacObject({})))
        )
      : of(new StacObject({}));
  }

  readStacCollections(url: string): Observable<StacCollections> {
    return url
      ? this.http.get(url).pipe(
          map((obj: any) => new StacCollections(obj)),
          catchError(() => of(new StacCollections({})))
        )
      : of(new StacCollections({}));
  }

  readStacItems(url: string): Observable<StacItemCollection> {
    return url
      ? this.http.get(url).pipe(
          map((obj: any) => new StacItemCollection(obj)),
          catchError(() => of(new StacItemCollection({})))
        )
      : of(new StacItemCollection({}));
  }

  readChildren(obj: StacObject) {
    const collectionsLink = obj.getLink('data');
    return this.readStacCollections(collectionsLink?.href || '').pipe(
      map((stacCollection) => stacCollection.collections.map((collectionObj) => collectionObj)),
      mergeMap((collections) => {
        let childLinks = obj.getLinks('child');
        collections.forEach((collection) => {
          const selfUrl = collection.url;
          childLinks = childLinks.filter(
            (link) => Location.stripTrailingSlash(link.href) != Location.stripTrailingSlash(selfUrl)
          );
        });
        let children$: Observable<StacObject> =
          childLinks.length > 0
            ? from(childLinks).pipe(mergeMap((link) => this.readStacObject(link.href)))
            : of();
        return concat(from(collections), children$).pipe(toArray());
      })
    );
  }

  readItems(obj: StacObject) {
    let itemLinks = obj.getLinks('item');

    const itemsLink = obj.getLink('items');
    let itemsList$: Observable<StacObject> = itemsLink
      ? this.readStacItems(itemsLink.href).pipe(
          map((itemCollection) =>
            itemCollection.items.map((itemObj) => {
              return itemObj;
            })
          ),
          tap((items) => {
            items.forEach((item) => {
              const selfUrl = item.url;
              itemLinks = itemLinks.filter(
                (link) =>
                  Location.stripTrailingSlash(link.href) != Location.stripTrailingSlash(selfUrl)
              );
            });
          }),
          mergeMap((items) => from(items))
        )
      : of();

    let items$: Observable<StacObject> =
      itemLinks.length > 0
        ? from(itemLinks).pipe(mergeMap((link) => this.readStacObject(link.href)))
        : of();

    return concat(itemsList$, items$).pipe(toArray());
  }
}
