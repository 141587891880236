export class StacExtent {
  spatial = new StacSpatialExtent({});
  temporal = new StacTemporalExtent({});

  constructor(obj: any) {
    if (obj.spatial) this.spatial = new StacSpatialExtent(obj.spatial);
    if (obj.temporal) this.temporal = new StacTemporalExtent(obj.temporal);
  }
}

export class StacSpatialExtent {
  bbox: number[][] = [];

  constructor(obj: any) {
    if ('bbox' in obj) {
      for (const key in obj.bbox) {
        const box = obj.bbox[key];
        let vals: number[] = [];
        for (const valkey in box) {
          vals.push(Number(box[valkey]).valueOf());
        }
        this.bbox.push(vals);
      }
    }
  }
}

export class StacTemporalExtent {
  interval: (Date | null)[][] = [];

  constructor(obj: any) {
    if ('interval' in obj) {
      for (const key in obj.interval) {
        let vals: (Date | null)[] = [];
        for (const val of obj.interval[key]) {
          if (val == 'null') {
            vals.push(null);
          } else {
            vals.push(new Date(val));
          }
        }
        this.interval.push(vals);
      }
    }
  }

  getIntervalAsString(index: number): string {
    const interval = this.interval[index];
    return StacTemporalExtent.getIntervalAsString(interval);
  }

  getIntervalsAsStrings(): string[] {
    let intervalStrings: string[] = [];
    this.interval.forEach((interval) => {
      intervalStrings.push(StacTemporalExtent.getIntervalAsString(interval));
    });
    return intervalStrings;
  }

  static getIntervalAsString(interval: (Date | null)[]): string {
    if (interval[0] == null) {
      if (interval[1] == null) {
        return '';
      } else {
        return '=> ' + interval[1].toUTCString();
      }
    }
    if (interval[1] == null) {
      return interval[0].toUTCString() + ' =>';
    }

    if (interval[0] == interval[1]) {
      return interval[0].toUTCString();
    }

    return interval[0].toUTCString() + ' => ' + interval[1].toUTCString();
  }
}
