import { StacLink } from './stac-link.model';
import { StacObject } from './stac-object.model';

export class StacCollections {
  collections: StacObject[] = [];
  links: StacLink[] = [];
  extras: Map<string, any> = new Map();

  constructor(obj: any) {
    if (obj.collections) {
      for (const collection of obj.collections) {
        this.collections.push(new StacObject(collection));
      }
    }

    if (obj.links) {
      for (const link of obj.links) {
        this.links.push(new StacLink(link));
      }
    }

    for (const key in obj) {
      if (!this.extras.has(key) && !(key in this)) {
        this.extras.set(key, obj[key]);
      }
    }
  }
}
