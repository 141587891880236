import { StacLink } from './stac-link.model';
import { StacExtent, StacTemporalExtent } from './stac-extent.model';
import { StacAsset } from './stac-asset.model';

export class StacObject {
  private _url: string = '';
  private _type: string = '';
  stac_version: String = '';
  license?: string;
  id: string = '';
  title?: string;
  description?: string;
  collection?: string;
  keywords?: string[];
  links: StacLink[] = [];
  assets: StacAsset[] = [];
  properties: Map<string, any> = new Map();

  constructor(obj: any, url?: string) {
    if (!obj || Object.keys(obj).length == 0) return;

    if (url) this._url = url;
    if (obj.stac_version) this.stac_version = obj.stac_version;
    if (obj.license) this.license = obj.license;
    if (obj.type) this._type = obj.type;
    if (obj.id) this.id = obj.id;
    if (obj.title) this.title = obj.title;
    if (obj.description) this.description = obj.description;
    if (obj.collection) this.collection = obj.collection;
    if (obj.keywords) this.keywords = obj.keywords;
    if (obj.links) this.links = obj.links;
    if (obj.extent) {
      this.properties.set('extent', new StacExtent(obj.extent));
    }
    if ('assets' in obj) {
      for (const key in obj.assets) {
        const assetObj = obj.assets[key];
        this.assets.push(new StacAsset(key, assetObj));
      }
    }

    // Parse all remaining keys
    for (const key in obj) {
      if (!this.properties.has(key) && !(key in this)) {
        this.properties.set(key, obj[key]);
      }
    }

    if ('properties' in obj) {
      for (const key in obj.properties) {
        if (!this.properties.has(key) && !(key in this)) {
          this.properties.set(key, obj.properties[key]);
        }
      }
    }
  }

  get type(): string {
    if (this._type.toLowerCase() == 'feature') {
      return 'Item';
    } else {
      return this._type;
    }
  }

  get url(): string {
    if (this._url) {
      return this._url;
    }
    return this.getLink('self')?.href || '';
  }

  set url(url: string) {
    this._url = url;
  }

  hasValue(): boolean {
    return this.id.length > 0;
  }

  getLinks(rel: string): StacLink[] {
    return this.links.filter((link) => link.rel == rel);
  }

  getLink(rel: string): StacLink | undefined {
    const all = this.getLinks(rel);
    if (all.length > 0) {
      return all[0];
    }
    return undefined;
  }

  getRootUrl(): string {
    return this.getLink('root')?.href || '';
  }

  getParentUrl(): string {
    if (this.type === 'Feature' || this.type === 'Item') {
      return this.getLink('parent')?.href || this.getLink('collection')?.href || '';
    }
    return this.getLink('parent')?.href || '';
  }

  find(term: string) {
    const lowerTerm = term.toLowerCase();
    if (
      this.id.toLowerCase().includes(lowerTerm) ||
      this.title?.toLowerCase().includes(lowerTerm) ||
      this.description?.toLowerCase().includes(lowerTerm) ||
      this.keywords?.filter((k) => k.toLowerCase().includes(lowerTerm)).length
    )
      return true;

    for (const asset of this.assets) {
      if (asset.find(term)) return true;
    }

    return false;
  }

  /**
   * Get the spatial extents of the STAC object.
   *
   * @returns The spatial extents in the form [[xmin, ymin, xmax, ymax]]
   */
  getSpatialExtent(): number[][] {
    if (this.properties.get('extent')?.spatial.bbox.length > 0) {
      return this.properties.get('extent').spatial.bbox;
    } else if (this.properties.get('bbox')) {
      return [this.properties.get('bbox')];
    }
    return [];
  }

  getGeometry(): any {
    return this.properties.get('geometry');
  }

  /**
   * Get the temporal extents of the STAC object.
   *
   * @returns The temporal extents formatted as a UTC string.
   */
  getTemporalExtent(): string[] {
    if (this.properties.get('extent')?.spatial.bbox.length > 0) {
      return this.properties.get('extent').temporal.getIntervalsAsStrings();
    } else if (this.properties.has('datetime') && this.properties.get('datetime') != 'null') {
      return [new Date(this.properties.get('datetime')).toUTCString()];
    } else {
      let startDate: Date | null =
        this.properties.get('start_datetime') && this.properties.get('start_datetime') != 'null'
          ? new Date(this.properties.get('start_datetime'))
          : null;
      let endDate: Date | null =
        this.properties.get('end_datetime') && this.properties.get('end_datetime') != 'null'
          ? new Date(this.properties.get('end_datetime'))
          : null;

      if (startDate && endDate) {
        return [StacTemporalExtent.getIntervalAsString([startDate, endDate])];
      }
    }

    return [];
  }

  /**
   * Get the url of the STAC object's thumbnail image.
   *
   * @returns The url of the STAC object's thumbnail image.
   */
  getThumbnailUrl(): string {
    for (const asset of this.assets) {
      if (asset.roles?.includes('thumbnail')) {
        return asset.href;
      }
    }
    return '';
  }
}
