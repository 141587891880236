export class StacLink {
  href: string = '';
  rel: string = '';
  type?: string;
  title?: string;
  method?: string;
  extras: Map<string, any> = new Map();

  constructor(obj: any) {
    if (obj.href) this.href = obj.href;
    if (obj.rel) this.rel = obj.rel;
    if (obj.type) this.type = obj.type;
    if (obj.title) this.title = obj.title;
    if (obj.method) this.method = obj.method;
    for (const key in obj) {
      if (!this.extras.has(key) && !(key in this)) {
        this.extras.set(key, obj[key]);
      }
    }
  }
}
