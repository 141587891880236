import { Injectable, computed, inject, signal } from '@angular/core';
import { catchError, mergeMap, of, tap } from 'rxjs';
import { StacAPIService } from './stac-api.service';
import { StacObject } from '../models/stac-object.model';

class StacObjectState {
  root: StacObject = new StacObject({});
  stacObj: StacObject = new StacObject({});
  children: StacObject[] = [];
  items: StacObject[] = [];
  loading: boolean = false;
  error: string = '';
}

@Injectable({
  providedIn: 'root',
})
export class StacObjectStateService {
  private stacAPIService = inject(StacAPIService);
  private _currentState = signal<StacObjectState>(new StacObjectState());

  load(url: string) {
    let initialState = new StacObjectState();
    initialState.loading = true;
    this._currentState.set(initialState);

    this.stacAPIService
      .readStacObject(url)
      .pipe(
        tap((obj: StacObject) => {
          this._currentState.set({
            ...this._currentState(),
            stacObj: obj,
          });
        }),
        mergeMap(() => {
          const rootUrl = this._currentState().stacObj.getRootUrl();
          return this.stacAPIService.readStacObject(rootUrl).pipe(
            tap((rootObj: StacObject) => {
              this._currentState.set({
                ...this._currentState(),
                root: rootObj,
              });
              localStorage.setItem('rootCatalog', this._currentState().root.url);
            })
          );
        }),
        mergeMap(() => {
          return this.stacAPIService.readChildren(this._currentState().stacObj).pipe(
            tap((children: StacObject[]) => {
              this._currentState.set({
                ...this._currentState(),
                children: children,
              });
            })
          );
        }),
        mergeMap(() => {
          return this.stacAPIService.readItems(this._currentState().stacObj).pipe(
            tap((items: StacObject[]) => {
              this._currentState.set({
                ...this._currentState(),
                items: items,
              });
            })
          );
        }),
        catchError((error) => {
          console.log('Error:', error.message);
          initialState.loading = false;
          initialState.error = error.message;
          this._currentState.set(initialState);
          return of({ error: error });
        })
      )
      .subscribe();
  }

  clear() {
    this._currentState.set(new StacObjectState());
  }

  root = computed(() => this._currentState().root);
  stacObj = computed(() => this._currentState().stacObj);
  children = computed(() => this._currentState().children);
  items = computed(() => this._currentState().items);
  isLoading = computed(() => this._currentState().loading);
  hasError = computed(() => this._currentState().error !== '');
}
